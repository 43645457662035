const videoImages = document.querySelectorAll('.video-img');
videoImages.forEach((videoImage) => {
  videoImage.addEventListener('click', function () {
    var video = '<iframe src="' + this.getAttribute('data-video') + '" allow="autoplay" allowfullscreen></iframe>';
    this.outerHTML = video;
  });
  videoImage.addEventListener('mouseover', function () {
    this.style.cursor = 'pointer';
  });
});
